import Carousel from "@/components/types/Carousel";

function Learn() {
  const {
    components: {
      examples: { component_ids, by_id },
    },
  } = window;

  return <Carousel kind="example" componentIds={component_ids} byId={by_id} />;
}

export default Learn;
