import React from "react";
import ReactDOM from "react-dom/client";
import Learn from "../components/Learn.jsx";

const $target = document.getElementById("learn");
let hasRendered = false;

const callback = (entries) => {
  entries.forEach((entry) => {
    const intersecting = entry.isIntersecting;

    if (!hasRendered && intersecting) {
      render();
    }
  });
};

const render = () => {
  hasRendered = true;

  ReactDOM.createRoot($target).render(
    <React.StrictMode>
      <Learn />
    </React.StrictMode>,
  );
};

const observer = new IntersectionObserver(callback);
observer.observe($target);
